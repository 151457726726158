import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../../shared/components/Layouts/Inner';
import SEO from '../../shared/components/SEO';
import Section from '../../shared/components/Section';
import Block from '../../shared/components/Block';
import ContainerFixed from '../../shared/components/Layouts/ContainerFixed';
import { StyledContainer } from '../../shared/components/Locations/List';
import { StyledButtonLink } from '../../shared/components/Button';

export const query = graphql`
  {
    locations: allContentfulLocations(sort: { fields: [sortOrder], order: ASC }) {
      nodes {
        id
        location
        slug
        areasCovered
        heroImage {
          fixed(width: 690, height: 250) {
            ...GatsbyContentfulFixed
          }
          file {
            url
          }
        }
      }
    }
  }
`;

const BookNow = ({ data }) => {
  return (
    <Layout darkMode>
      <SEO title="Book Now" />

      <Section heading="Please choose which location you are from" contained>
        <p>
          All our cleaning services are currently available throughout the North East serving
          Newcastle, Gateshead, North Tyneside, and surrounding areas.
        </p>
      </Section>

      <ContainerFixed>
        <Block columns="1fr 1fr 1fr" breakpoint="995px">
          {data.locations.nodes.map((location) => (
            <StyledContainer key={location.id}>
              {location.heroImage && (
                <Link style={{ width: '100%' }} to={`/book-now/${location.slug}`}>
                  <Img
                    style={{ width: '100%' }}
                    fixed={location.heroImage.fixed}
                    alt={location.location}
                  />
                </Link>
              )}
              <div className="content">
                <h2>
                  <Link to={`/book-now/${location.slug}`}>{location.location}</Link>
                </h2>
                <p>
                  <StyledButtonLink to={`/book-now/${location.slug}`}>Get A Quote</StyledButtonLink>
                </p>
              </div>
            </StyledContainer>
          ))}
        </Block>
      </ContainerFixed>
    </Layout>
  );
};

export default BookNow;
